import { instance } from "./index";

export const getManualVersions = async (account, name) => {
  const browserLang = window.navigator.language.slice(0, 2);
  
  return instance.get(`accounts/${account}/manuals/${name}/versions`).then((res) => {
    let versionList = res.data;

    const findBrowserManualLang = versionList && versionList.data.filter(({lang}) => lang === browserLang);
    const findEnglishVersion = versionList && versionList.data.filter(({lang}) => lang === "en"); 
   


    // TODO: LOCAL: change to local 
    // const updatedUrlParts = versionList.data[0].url?.split("/"); 
    // const updatedUrlLocal = `http://localhost:3000/${updatedUrlParts[3]}/${updatedUrlParts[4]}`;  
    // return window.location.replace(updatedUrlLocal);

    if(findBrowserManualLang.length > 0) {
      window.location.replace(findBrowserManualLang[0].url);
    } else  if(findEnglishVersion.length !== 0) {
      window.location.replace(findEnglishVersion[0].url);
    } else { 
      return window.location.replace(versionList.data[0].url);
    }    
  })
}

export const getManual = async (account, name, lang, isPreview) => {
  return isPreview
    ? instance.get(`accounts/${account}/manuals/${name}/lang/${lang}/preview`)
    : instance.get(`accounts/${account}/manuals/${name}/lang/${lang}`);
};

export const getSubPage = async (account, name, lang, isPreview, page) => {
  return isPreview
    ? instance.get(`accounts/${account}/manuals/${name}/lang/${lang}/pages/${page}/preview`)
    : instance.get(`accounts/${account}/manuals/${name}/lang/${lang}/pages/${page}`);
};

export const handleSearchData = async ({account, manualName, en, isPreview, page}, query) => {
  if(page) {
    return instance.get(`accounts/${account}/manuals/${manualName}/lang/${en}/pages/${page}/search/?q=${query}${isPreview ? '&mode=preview': ""}`)
  } else {
    return instance.get(`accounts/${account}/manuals/${manualName}/lang/${en}/search/?q=${query}${isPreview ? '&mode=preview' : ""}`);
  }
};


export const getSiteId = async (url) => {
  return instance.get(`analytics/site/id?siteUrl=${url}`)
};
