import React, { createContext, useReducer } from "react";

import AppReducer from "./AppReducer";

const initialState = {
  data: null,
  siteId: '',
  loaded: false,
  searchResult: null,
  searchLoading: false,
  searchError: null,
  isSubPage: null,
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState); 

  return (
    <GlobalContext.Provider
      value={{
        globalState: state,
        dispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
