import React, { useRef, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { handleSearchData } from "../../api/manual";
import styled from "styled-components";
import getSubPageName from "../../utils/getSubPageName";
import { GlobalContext } from "../../context/GlobalState";
import { ReactComponent as SearchIcon } from "../../assets/images/Seach-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Menu = ({ open, height, subPages, setOpenMenu, parentPage }) => {
  const { globalState, dispatch } = useContext(GlobalContext);
  const {searchResult} = globalState;
  const [searchTerm, setSearchTerm] = useState("");
  const [searchLocalError, setSearchLocalError] = useState(null);
  const ref = useRef();
  const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    useEffect(() => {
      //document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
  };

  useOutsideClick(ref, () => {
    setOpenMenu(false);
  });
  
  const getSearchData = async (pageData, query) => {
    dispatch({
        type: "SEARCH_REQUEST"
      });
      const response = await handleSearchData(pageData, query); 
      if(response.data) {
        dispatch({
          type: "SEARCH_SUCCESS",
          payload: response.data.data
        });
      } else {
        dispatch({
          type: "SEARCH_ERROR",
        });
      }
  }

  const handleChange = (event) => {
    let search = event.target.value;
    if(search.length === 0) {
      dispatch({
        type: "CLEAR_SEARCH",
      });
    }
    if(search.length < 2 && search.length !== 0) {
      setSearchLocalError("Min search length is 2 characters")
    } else {
      setSearchLocalError(null);
      search && getSearchData(globalState.urlRequest, search)
    }
    setSearchTerm(search);
  };

  const results = !searchResult
  ? subPages
  : searchResult &&
  searchResult; 
   
  const subPagesList =
    (subPages || searchResult) &&
    results.map(({ title, url }, index) => {
      const newPath = getSubPageName(url);
      return (
        <li key={index} className="menu-list__item" onClick={() =>{setOpenMenu(false)}}>
          <Link to={`/${newPath}/`}>{title}</Link>
        </li>
      );
    });

  const renderParentPage = () => {
    const subPageName = parentPage && getSubPageName(parentPage);
    return parentPage ? (
      <li key={-1} className="menu-list__item withArrow">
        <Link to={`/${subPageName}/`}>
          <span className="icon-arrow">
            <FontAwesomeIcon icon={"arrow-left"} />
          </span>
          {"Go back to product"}
        </Link>
      </li>
    ) : null;
  };

  return (
    <StyledMenu open={open} height={height} className={"menu"}>
      <p className="title search-msg">What are you looking for?</p>
      <label className="search-label" ref={ref}>
        <SearchIcon />
        <input
          className="input-search"
          placeholder={"Type something"}
          value={searchTerm}
          onChange={handleChange}
        />
        {false && <div className="loader-05"></div>}
      </label>
      <ul className="menu-list" >
        {renderParentPage()}
        {results.length > 0 && subPagesList}
        {searchResult && searchResult?.length === 0 && "Nothing found"}
      </ul>
    </StyledMenu>
  );
};
const StyledMenu = styled.div`
  display: flex;
  position: absolute;
  top: ${({ height }) => (height ? `${height}px` : "86px")};
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 16px 48px 16px;
  overflow: scroll;
  background-color: ${(props) => props.theme.page_background_color};
  color: ${(props) => props.theme.block_title_font_color};
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s linear;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100vw)")};
  z-index: 99999;
  .title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 15px;
  }
  .loader-05 {
    width: 15px;
    height: 15px;
    right: 15px;
    border: .2em solid transparent;
    border-top-color: currentcolor;
    border-radius: 50%;
    animation: 1s loader-05 linear infinite;
    position: absolute;
    &:before {
      content: '';
      display: block;
      width: inherit;
      height: inherit;
      position: absolute;
      top: -.2em;
      left: -.2em;
      border: .2em solid currentcolor;
      border-radius: 50%;
      opacity: .5;
    }
  }
  
  @keyframes loader-05 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .error {
    display: flex;
    color: red;
    align-self: flex-start;
    padding: 0 15px;
  }
  .menu-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${(props) => props.theme.page_background_color};
    .menu-list__item {
      margin-bottom: 20px;
      font-size: 16px;
      a {
        text-decoration: none;
        color: ${(props) => props.theme.block_title_font_color};
      }
    }
  }
  .search-label {
    width: 100%;
    background-color: #f7f7f7;
    padding: 12px 47px;
    position: relative;
    border: 1px solid rgba(18, 83, 215, 0.7);
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    svg {
      position: absolute;
      left: 20px;
      path {
        fill: ${(props) => props.theme.burger_color};
      }
    }
    .input-search {
      border: none;
      width: 100%;
      outline: none;
      background-color: #f7f7f7;
    }
  }
  .withArrow {
    position: relative;
    list-style-type: none;
    .icon-arrow {
      position: absolute;
      left: -20px;
    }
  }
`;
