import React from "react";
import styled from "styled-components";

export const Text = ({data}) => {
  const {title, text } = data || {};

  function createMarkup() {
    return {__html: text};
  }

  if(!title?.length && !text?.length) return null;

  return (
    <StyledTextComponent className="text-block">
       {title && <h4 className="title block-title-font">{title}</h4>}
      <div className='content' dangerouslySetInnerHTML={createMarkup()}>
      </div>
    </StyledTextComponent>
  );
};

const StyledTextComponent = styled.div`
  width: 100%;
  padding: 20px; 
  font-family: ${(props) => props.theme.block_title_font?.split('=')[1]?.replace(/\+/g, ' ')};
  color: ${(props) => props.theme.block_title_font_color};
  img {
    max-width: 320px;
  }
  .title { 
    margin-bottom: 12px;
    font-size: 22px;
  }
  .content {
    p {
        margin: 0;
        line-height: 1.5;
    }
    h1,h2,h3,h4,h5,h6 {
        margin: 0;
    }
    h4 {
      font-size: 20px;
      font-weight: 900;
    }
  }

`;
