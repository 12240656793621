import React from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from '@fortawesome/free-solid-svg-icons';

export const Button = ({ data }) => {    
  
  return (
    <StyledButton
      className="link-button"
      href={data?.file ? data?.file : data?.url}
      target={data?.target_blank ? "_blank" : ""}
    >
      {data?.icon && (
        <span className="icon x">
          <FontAwesomeIcon key={data?.icon} icon={Icons[data?.icon?.split(' ')[1]]} />
        </span>
      )} 
      {data?.title}
    </StyledButton>
  );
};

const StyledButton = styled.a`
  width: 90%;
  max-width: 343px;
  height: 46px;
  border: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 20px auto;
  font-size: 16px;
  font-family: ${(props) => props.theme.link_button_font?.split('=')[1]?.replace(/\+/g, ' ')};
  text-decoration: none;
  border-width: ${(props) => props.theme.link_button_border_width}px;
  border-radius: ${(props) => props.theme.link_button_border_radius}px;
  border-color: ${(props) => props.theme.link_button_border_color};
  background: ${(props) => props.theme.link_button_background_color};
  color: ${(props) => props.theme.link_button_font_color}; 
  text-transform: ${(props) => props.theme.link_button_text_transform_uppercase  ? "uppercase" : "unset" } ;
  font-weight: ${(props) =>
    props.theme.link_button_font_weight ? "bold" : "normal"};

  .icon {
    width: 24px;
    height: 24px;
    display: block;
    margin-right: 15px;
    svg {
      width: 24px;
      height: 24px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
`;
