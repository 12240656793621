export default (state, action) => {
  switch (action.type) {
    case "GET_MANUAL_DATA":
      return {
        ...state,
        data: action.payload,
        loaded: true,
        isSubPage: false,
      };

    case "GET_SITE_ID":
      return {
        ...state,
        siteId: action.payload,
      };
    case "GET_MANUAL_REQUEST":
      return {
        ...state,
        loaded: false,
      };
    case "GET_MANUAL_ERROR":
      return {
        ...state,
        loaded: false,
        error: true,
        errorMsg: action.payload,
      };

    case "GET_SUBPAGE_DATA":
      return {
        ...state,
        data: action.payload,
        loaded: true,
        isSubPage: true,
      };
    case "GET_SUBPAGE_ERROR":
      return {
        ...state,
        loaded: false,
        error: true,
        errorMsg: action.payload,
      };
    case "SAVE_PARENT_PAGE":
      return {
        ...state,
        parentPageUrl: action.payload,
      };
    case "SEARCH_REQUEST":
      return {
        ...state,
        searchLoading: true,
      };
    case "SEARCH_SUCCESS":
      return {
        ...state,
        searchLoading: false,
        searchResult: action.payload,
      };
    case "SEARCH_ERROR":
      return {
        ...state,
        searchLoading: false,
        searchError: false,
        searchResult: false,
      };
    case "CLEAR_SEARCH":
      return {
        ...state,
        searchResult: false,
        searchError: false,
      };
    case "SAVE_URL_REQUEST":
      return {
        ...state,
        urlRequest: action.payload,
      };

    default:
      return state;
  }
};
