import React from "react";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";

import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.less";

export const Slider = ({ data, firstSlider }) => {
  const { images, title } = data;

  const needSlider = images.length > 1 ? true : false;
  SwiperCore.use([Pagination]);

  return (
    <StyledLangContainer className='image-block' styles={firstSlider}>
      {needSlider ? (
        <div className="image-container-slider">
        {title && <h4 className="title block-title-font">{title}</h4>}
        <Swiper
          slidesPerView={1}
          onSlideChange={() => {}}
          onSwiper={(swiper) => {}}
          pagination={{ clickable: true }}
          autoHeight={true}
        >
          {images.map((slideContent) => (
            <SwiperSlide key={slideContent}>
              <img src={slideContent} alt=""></img>
            </SwiperSlide>
          ))}
        </Swiper>
        </div>
      ) : (
        <div className="image-container">
          {title && <h4 className="title block-title-font">{title}</h4>}
          <img src={images[0]} alt=""></img>
        </div>
      )}
    </StyledLangContainer>
  );
};

const StyledLangContainer = styled.div`
  display: flex;
  max-width: 768px;
  flex-direction: column;
  padding: ${({ styles }) => (styles ? "0 0 20px 0" : "20px 0 ")};
  color: ${(props) => props.theme.block_title_font_color};
  .image-container-slider {
    margin-top: 20px;
    position: relative;
    h4 {
      position: relative;
      margin-bottom: 12px;
      padding: 0 12px;
      font-size: 22px;
      color: ${(props) => props.theme.block_title_font_color};
    }
  }
  .image-container {
    width: 100%;
    height: 100%;
    h4 {
      font-size: 20px;
      padding: 0 20px 12px 20px;
    }
  }
  .swiper-pagination-bullets {
    bottom: 20px;
  }
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    border: 1px solid #fff;
    opacity: 0.6;
    background-color: transparent;
    transition: all 0.3s linear;
  }
  .swiper-pagination-bullet-active {
    width: 30px;
    border-radius: 8px;
    background-color: #fff;
    opacity: 1;
  }
  .swiper-slide {
  }
`;
