import React from "react";
import styled from "styled-components";

export const ManualNotFound = ({ location, history }) => {
  return <StyledContainer className="flex-center position-ref full-height">
    <div className="code">404</div>
    <div className="message">
                Not Found            
                </div>
    </StyledContainer>;
};

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
  color: #636b6f;
  font-family: 'Nunito', sans-serif;
  font-weight: 100;
  height: 100vh;
  margin: 0;
  .code {
      border-right: 2px solid;
      font-size: 26px;
      padding: 0 15px 0 15px;
      text-align: center;
  }
  .message {
    font-size: 18px;
    text-align: center;
    padding: 10px;
  }
`;