import React,{useRef, useEffect} from "react";
import {Link} from 'react-router-dom';
import { ReactComponent as Arrow } from "../../assets/images/Arrow.svg";
import styled from "styled-components";


export const LangSwitcher = ({ open, setOpen, languageList, data }) => {
  const ref = useRef();
  const listLang = languageList && languageList.map(({title, lang_full, url}) => {
    // TODO: For local test
    const updatedUrlParts = url.split('/');
    const updatedUrlLocal = `http://localhost:3000/${updatedUrlParts[3]}/${updatedUrlParts[4]}`
 
    return  <li key={title}><a href={url}>{lang_full}</a></li>
  }
 
  );

  const useOutsideClick = (ref, callback) => {
    const handleClick = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    useEffect(() => {
      document.addEventListener("click", handleClick);
  
      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
  };
  useOutsideClick(ref, () => {
    setOpen(false);
  });
  
  return languageList && languageList.length > 0 ? (
    <StyledLangContainer className="lang-swither-container" open={open} onClick={() => setOpen(!open)} ref={ref}>
      <p className="current-lang">
        {data && data.lang_full}
        <Arrow />
      </p>
      <ul className="lang-dropdown">
        {listLang}
      </ul>
    </StyledLangContainer>
  ) : ('')
};
const StyledLangContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 30px;
    .current-lang {
        font-family:  ${(props) => props.theme.lang_dropdown_font?.split('=')[1]?.replace(/\+/g, ' ')};
        color: ${(props) => props.theme.lang_dropdown_font_color};  
        font-size: 16px;
        margin: 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-transform: Capitalize;
        z-index: 1;
        &::before {
          min-width: 86px;
          content: "";
          opacity: ${({ open }) => open ? "1" : "0"};
          position: absolute;
          width: 128%;
          height: 39px;
          top: -9px;
          left: -11px;
          z-index: -1;
          transition: 0.15s cubic-bezier(.2, 0, 0, 1.6);
          background-color: ${(props) => props.theme.page_background_color};
          transform: ${({ open }) => open ? "transform: none;" : 'scale(0.6) translateY(-20%);'}
        }
        svg {
          margin-left: 5px;
          path {
            fill: ${(props) => props.theme.burger_color};
          }
        }
    }
    .lang-dropdown {
      min-width: 86px;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 30px;
      left: -11px;
      z-index: 10;
      background-color: ${(props) => props.theme.page_background_color};
      color: ${(props) => props.theme.header_title_subtitle_font_color};
      padding: 10px 18px 10px 12px;
      opacity: ${({ open }) => open ? "0.999" : '0'};
      transition: 0.4s cubic-bezier(.2, 0, 0, 1.6);
      transform: ${({ open }) => open ? "transform: none;" : 'scale(0.6) translateY(-20%);'};
      margin: 0;
      list-style-type: none;
      color: ${(props) => props.theme.lang_dropdown_font_color};
      a {
        color: ${(props) => props.theme.lang_dropdown_font_color};
        text-decoration: none;
        text-transform: Capitalize;
      }
    }
`;
