import React, {useEffect, useState} from "react";
import styled from "styled-components";


export const CookieBanner = ({ data }) => {
    const [showBanner, setShowBanner] = useState(data.is_enabled_privacy);

    const acceptHandler = () => {
        setShowBanner(false);
        localStorage.setItem('cookieBanner', 'accept');
    }

    const declineHandler = () => {
        setShowBanner(false);
        localStorage.setItem('cookieBanner', 'decline');
    }

    useEffect(() => {
        const cookieAccept = localStorage.getItem('cookieBanner');
        if(cookieAccept === 'accept' || cookieAccept === 'decline') {
            setShowBanner(false);
        }
    }, [])

    if(showBanner) {

        let declineButtonVisible = false;

        if(data.decline_label && data.decline_label.trim().length > 0){
            declineButtonVisible = true;
        }

        return (
            <BannerContainer>
                <PrivacyContent>
                    <PrivacyDescription>{data.privacy_description}</PrivacyDescription>
                    <PrivacyLink
                        href={data.privacy_link}
                        target="_blank"
                    >
                        {data.privacy_label}
                    </PrivacyLink>
                </PrivacyContent>
                <ButtonsWrapper>
                    {declineButtonVisible && (
                    <Button onClick={declineHandler} className="decline">{data.decline_label}</Button>
                    )}
                    <Button onClick={acceptHandler}>{data.accept_label}</Button>
                </ButtonsWrapper>
            </BannerContainer>
          )
    }

    return null;

};

const BannerContainer = styled.div`
    position: sticky;
    bottom: 0;
    left: 0;
    width:100%;
    background: #414141;
    display: flex;
    padding: 12px 24px;
    @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
      }
`;

const PrivacyContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin-right: 20px;
    @media (max-width: 700px) {
        margin-bottom: 20px;
      }
`;

const PrivacyDescription = styled.div`
    color: #ffffff;
    margin-bottom: 8px;
`;

const PrivacyLink = styled.a`
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 375px) {
        flex-direction: column;
      }
`;

const Button = styled.div`
    height: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    border-radius: 5px;
    background: #ffffff;
    color: #000000;
    font-weight: 600;
    cursor: pointer;
    white-space: nowrap;
    @media (max-width: 375px) {
        min-width: 180px;
      }
    &.decline {
        margin: 0 24px 0 0;
        @media (max-width: 375px) {
            margin: 0 0 20px 0;
          }
    }
`;



