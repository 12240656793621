import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import VideoBlock from './video-block';
import 'intersection-observer';
import { useMediaQuery } from 'react-responsive';
import { GlobalContext } from '../../context/GlobalState';

import RotatePhoneImage from '../../assets/images/rotate.png';


export const Video = ({ data }) => {
  const { title, scenario, media_id, account_id, aspect_ratio, config_id, player_params } = data || {};

  const { globalState } = useContext(GlobalContext);
  const {data: accountData} = globalState;  

  const isTouchDevice = useMediaQuery({ query: '(pointer: coarse)' });
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' }); 
  const isMobileLandscape = isTouchDevice && isLandscape;

  
  const aspectRationForIframe = useMemo(() => {
    const getAspectData = aspect_ratio.split(':');
    return (
      getAspectData.length > 1 &&
      (parseInt(getAspectData[1]) / parseInt(getAspectData[0])) * 100
    );
  }, [aspect_ratio]);

  const browserLang = window.navigator.language.slice(0, 2);

  const videoRef = useRef(null);
  const [isVideoInView, setIsVideoInView] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.8,
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVideoInView(true);
          observer.unobserve(entry.target); // Stop observing once video is in view
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    if (data?.media_id?.length && videoRef) {
      observer.observe(videoRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);
  
 

  
  const isMobile = () => {
    return window.innerWidth < 768;  
  };
  
  const getIframeSrc = () => {
    const baseSrc = `https://play2.qbrick.com/qplayer/index.html?accountId=${account_id}&${scenario ? 'scenarioId' : 'mediaId'}=${media_id}&configId=${config_id == 'interactive' ? 'Interactive' : player_params?.config_id}&useLoader=true&inline=on&sharing=true`;
    

    if (isMobile()) {
      const autoplay = player_params?.mobile?.autostart !== undefined ? player_params?.mobile?.autostart : true; 
      const mute = player_params?.mobile?.mute !== undefined ? player_params?.mobile?.mute : true;
      const replay = player_params?.mobile?.replay !== undefined ? player_params?.mobile?.replay : true;

      return `${baseSrc}&autoplay=${isVideoInView ? autoplay : false}&mute=${mute}&repeat=${replay}`;
    } else {
      const autoplay = player_params?.desktop?.autostart !== undefined ? player_params?.desktop?.autostart : true; 
      const mute = player_params?.desktop?.mute !== undefined ? player_params?.desktop?.mute : true;
      const replay = player_params?.desktop?.replay !== undefined ? player_params?.desktop?.replay : true;
        
      return `${baseSrc}&autoplay=${isVideoInView ? autoplay : false}&mute=${mute}&repeat=${replay}`;
    }
  };

  const renderVideoPlayer = () => { 
    if(!data?.video && !data?.media_id?.length) return;

    if (data?.media_id?.length) {
      return (
        <> 
          <div className="video-container" > 
            <iframe   
              key={isVideoInView}
              ref={videoRef}
              title="video.mp4" 
              src={getIframeSrc()} 
              allowFullScreen="true" 
              frameborder="0" 
              border="0">
            </iframe> 
          </div>
        </>
      );
    } 

    if (!data?.media_id && data?.upload_type !== "embed") {
      return  <VideoBlock data={data} />;
    }

    if(data?.video && data?.upload_type === "embed"){
      return <div className='video-container' dangerouslySetInnerHTML={{ __html: data?.video }} />
    }
    
    return null; 
  };
  

  const shouldShowImage = isMobileLandscape && isLandscape && accountData?.account?.video_screen_orientation === 'portrait';

  if(!data?.video?.length && !data?.media_id?.length) return null;

  return (
    <StyledVideoBlcok
      className="video-block"
      aspectRationForIframe={aspectRationForIframe}
    >
      {title && <h4 className="video-title block-title-font">{title}</h4>}
      
      {shouldShowImage 
        ? <img className='rotate-phone' src={RotatePhoneImage} alt="Rotate Phone" />
        : renderVideoPlayer()
      } 
      
    </StyledVideoBlcok>
  );
};

const StyledVideoBlcok = styled.div`
  width: 100%;
  padding: 20px 0;
  .video-title {
    margin-bottom: 12px;
    padding: 0 12px;
    font-size: 22px;
    color: ${(props) => props.theme.block_title_font_color};
  }
  .video-container {
    height: 230px;
    position: relative;
    padding-bottom: ${({ aspectRationForIframe }) => aspectRationForIframe}%;
    height: 0;
    overflow: hidden;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
