import React, { useContext } from "react";
import { ThemeProvider, createGlobalStyle} from "styled-components";
import { GlobalContext } from "../context/GlobalState";
import {Helmet} from "react-helmet";

const Theme = ({ children }) => {
  const { globalState } = useContext(GlobalContext);

  const {data, siteId, loaded } = globalState; 
  
  const GlobalStyle = createGlobalStyle`
    ${data && (data.account.styles.css_box) }
`;

  let theme ={};

  const { styles } = loaded && data && data.account;
  if( data ) {

    theme = {
        block_title_font: loaded && styles.block_title_font,
        block_title_font_color: loaded && styles.block_title_font_color,
        burger_color: loaded && styles.burger_color,
        header_background_color: loaded && styles.header_background_color,
        header_title_subtitle_font: loaded && styles.header_title_subtitle_font,
        header_title_subtitle_font_color: loaded && styles.header_title_subtitle_font_color,
        lang_dropdown_font: loaded && styles.lang_dropdown_font,
        lang_dropdown_font_color: loaded && styles.lang_dropdown_font_color,
        link_button_background_color: loaded && styles.link_button_background_color,
        link_button_border_color: loaded && styles.link_button_border_color,
        link_button_border_radius: loaded && styles.link_button_border_radius,
        link_button_border_width: loaded && styles.link_button_border_width,
        link_button_font: loaded && styles.link_button_font,
        link_button_font_color: loaded && styles.link_button_font_color,
        link_button_text_transform_uppercase: loaded && styles.link_button_text_transform_uppercase,
        link_button_font_weight: loaded && styles.link_button_font_weight,
        page_background_color: loaded && styles.page_background_color,
    };
    
  } 

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
       <Helmet>
          <title>{data && data.manual && data.manual?.meta_info?.meta_title}</title>
          <meta
            name="description"
            content={data && data.manual && data.manual?.meta_info?.meta_description}
          />
          <link href={styles && styles.block_title_font} rel='stylesheet' type='text/css'/>
          <link href={styles && styles.header_title_subtitle_font} rel='stylesheet' type='text/css'/>
          <link href={styles && styles.lang_dropdown_font} rel='stylesheet' type='text/css'/>
          <link href={styles && styles.link_button_font} rel='stylesheet' type='text/css'/>
          <meta property={"og:image"} content={styles && styles.og_image} />
          <meta property={"og:description"} content={styles && styles.og_description} />

          {/* Google Analytics Script */}
          {data && data?.account?.enable_custom_tracking && (
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${data?.account?.ga_code ? data?.account?.ga_code : 'G-YZMKMDD86D'}`}></script>
          )}

          {/* Matomo Analytics Script */}
          {data && data?.account?.enable_analytics && siteId && (
            <script>
              {`
                var _paq = window._paq = window._paq || [];
                _paq.push(['trackPageView']);
                _paq.push(['enableLinkTracking']);
                (function() {
                  var u="//matomo.kolla-info.com/";
                  _paq.push(['setTrackerUrl', u+'matomo.php']);
                  _paq.push(['setSiteId', '${siteId}']);
                  var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                  g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
                })();
              `}
            </script>
          )}
      
        </Helmet>
        <GlobalStyle />
          {children}
      </React.Fragment>
    </ThemeProvider>
  );
  
};
export default Theme;
