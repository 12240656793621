import React from "react";
import { GlobalProvider } from "./context/GlobalState";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./router/Router";
import 'intersection-observer';
import Theme from './theme/Theme';
import './app.css';

function App() {
  return (  
    <GlobalProvider>
      <Theme>
        <BrowserRouter>
          <Routes/>
        </BrowserRouter>
      </Theme>
    </GlobalProvider>
  );
}

export default App;
