import React from 'react';
import styled from 'styled-components';

export const Iframe = ({ data }) => {
  const { title, url, height } = data || {};

  if(!url?.length) return null;
  
  return (
    <StyledIframeBlock className="ifame-block">
      {title && <h4 className="iframe-title block-title-font">{title}</h4>}
      <iframe
        src={url}
        width="100%"
        height={height || 400}
        loading="lazy"
        title={title}
        frameBorder="0"
        allowFullScreen
        sandbox="allow-forms allow-scripts allow-same-origin"
      ></iframe>
    </StyledIframeBlock>
  );
};

const StyledIframeBlock = styled.div`
  width: 100%;
  padding: 20px 0;
  .iframe-title {
    margin-bottom: 12px;
    padding: 0 12px;
    font-size: 22px;
    color: ${(props) => props.theme.block_title_font_color};
  }
`;
