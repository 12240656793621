import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalState.js';
import Videojs from './player.js';
import 'video.js/dist/video-js.css';   


export default function VideoBlock({data}) { 
  const { lang, manual  } = useParams(); 
  const localStorageLang = localStorage.getItem('subLang'); 
  
  const { globalState } = useContext(GlobalContext);
 
  const {data: accountData} = globalState; 
 
  const getDefaultSubLang = (itemLeng) => {
    const subtitleExists = data?.subtitlesFiles?.some(sub => sub.lang === localStorageLang);
    
    if (localStorageLang && subtitleExists) {
      if (localStorageLang && localStorageLang === itemLeng) {
        return true;
      }
      return false
    }

    if ((localStorageLang && !subtitleExists) || !localStorageLang) {
      if (lang === itemLeng) {
        return true;
      }
      return false
    } 
  }

  const getLanguageLabel = (langCode) => { 
    const displayNames = new Intl.DisplayNames(['en'], { type: 'language' });
    return displayNames.of(langCode); 
  }

  const options = {
    autoplay: !!data?.autostart,
    loop: !!data?.loop,
    muted: !data?.sound,
    poster: data.thumbnail, 
    controls: true, 
    sources: [{ src:  `${data.video}#t=0.001`,  type: 'video/mp4' }],
    tracks: !!data?.subtitles ?  data?.subtitlesFiles.map(sub => ({
      src: sub.fileUrl,
      kind: 'subtitles',
      srclang: sub.lang,
      label: getLanguageLabel(sub.lang),
      default: getDefaultSubLang(sub.lang)
    })) : []
  };   

  
  return (
    <div> 
      <Videojs  
        {...options}    
        manual={manual}
        subPageManual={globalState?.urlRequest?.subPageName}
        fontSize={accountData?.account?.video_subtitle_size || "18px"} 
      /> 
    </div>
  )
}
