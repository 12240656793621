import React, { useState, useContext, useRef } from "react";
import { GlobalContext } from "../../context/GlobalState";
import styled from "styled-components";

import { Burger } from "../Burger/Burger";
import { LangSwitcher } from "../LangSwitcher/LangSwitcher";
import { Menu } from "../Menu/Menu";

export const Header = () => {
  const [open, setOpen] = useState(false);
  const [opneLangSwitcher, setOpenLangSwitcher] = useState(false);
  
  const { globalState } = useContext(GlobalContext);

  const { data, loaded, isSubPage, parentPageUrl } = globalState;
  const logoSrc = data && data.account.styles.logo;
  const { title, subtitle, lang_versions, sub_pages} = !isSubPage ? (data && data.manual) || {} : (data && data.sub_page) || {}; 

  const { other_sub_pages, back_url} = (data && data) || {};
  const headerRef = useRef();

  let headerHeight = headerRef && loaded && headerRef.current && headerRef.current.offsetHeight;

  return (
    <StyledHeader className={'header header-background'} ref={headerRef}>
      <div className="left-content">
        <div className="logo-container">
          {logoSrc ? <img src={logoSrc} alt="logo" /> : null}
          <a className="link-page" href={parentPageUrl}></a>
        </div>
        <div className="manual-info header-title-subtitle-font">
          <h1 className="manual-title">{title}</h1>
          <span className="manual-subtitle"> {subtitle ? subtitle : " "}</span>
        </div>
      </div>
      <div className="right-content">
        <LangSwitcher
          open={opneLangSwitcher}
          setOpen={setOpenLangSwitcher}
          languageList={lang_versions}
          data={data && data.manual}
        />
        <Burger open={open} setOpen={setOpen} />
      </div>
      <Menu open={open} height={headerHeight} setOpenMenu={setOpen} parentPage={back_url} subPages={!isSubPage ? sub_pages : other_sub_pages} />
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 20px 15px;
  justify-content: space-between;
  font-family: ${(props) => props.theme.header_title_subtitle_font?.split('=')[1]?.replace(/\+/g, ' ')};
  background-color: ${(props) => props.theme.header_background_color};
  max-width: 768px;
  margin: 0 auto;
  z-index: 10;
  .left-content {
    display: flex;
    align-items: center;
    flex: 1;
    .logo-container {
      position: relative;
      .link-page {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;

      }
    }
    .manual-info {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      color: ${(props) => props.theme.header_title_subtitle_font_color};
    }
    .manual-title {
      font-weight: bold;
      margin-bottom: 5px;
    }
  
  }
  .right-content {
    display: flex;
    align-items: center;
  }
`;
