import React from "react";
import videojs from "video.js"; 

export default class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMuted: true,
      isPlaying: false,
      isHovered: false,
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleUnmute = this.handleUnmute.bind(this);
  }

  componentDidMount() {
    const { fontSize, tracks, ...options } = this.props;
    
    this.player = videojs(this.videoNode, options, () => {
       
      this.applySubtitleStyles(fontSize);
      this.setState({ isMuted: this.player.muted() });

      this.player.on('volumechange', () => {
        this.setState({ isMuted: this.player.muted() });
      });

      this.player.on("play", () => {
        this.setState({ isPlaying: true });

        if(window.innerWidth < 768){
          this.setState({ isHovered: true });
          setTimeout(() => {
            this.setState({ isHovered: false });
          }, 5000); 
        }

      });

      this.player.on("pause", () => {
        this.setState({ isPlaying: false });
      }); 

      if (tracks && tracks.length > 0) {
        tracks.forEach(track => {
          this.player.addRemoteTextTrack(track, false);
        });
      }

    });

    this.player.textTracks().on("change", (event) => {
      const languageTracks = Array.from(this.player.textTracks()).filter((track) => track.language);
      const activeTrack = languageTracks.find((track) => track.mode === "showing");
      const currentLanguage = activeTrack ? activeTrack.language : "";

      localStorage.setItem("subLang", currentLanguage); 
    });
 
    if (this.videoNode) {
      this.videoNode.setAttribute("webkit-playsinline", true);
      this.videoNode.setAttribute("playsinline", true);
    }
    
  } 

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  applySubtitleStyles(fontSize) {
    const subtitleDisplay = this.player.el().querySelector('.vjs-text-track-display');
    if (subtitleDisplay) {
      subtitleDisplay.style.fontSize = '';

      if (fontSize) {
        subtitleDisplay.style.fontSize = fontSize;
      }
    }
  }

  handleMouseEnter() {
    this.setState({ isHovered: true });
  }

  handleMouseLeave() {
    this.setState({ isHovered: false });
  }

  handleUnmute() {
    if (this.player) {
      this.player.muted(false);
    }
  }
 

  render() {
    const { isMuted, isPlaying, isHovered } = this.state;

    // There are five classes associated with fluid mode, vjs-fluid, vjs-16-9, vjs-4-3, vjs-9-16 and vjs-1-1.
    return (
      
      <div 
      
        data-vjs-player 
        onMouseEnter={this.handleMouseEnter} 
        onMouseLeave={this.handleMouseLeave}>
          
            <video 
              ref={node => (this.videoNode = node)}
              autoPlay={this.props?.options?.autoPlay}
              className="video-js"
              data-setup='{"fluid": true}'
              responsive={true}
              
              crossorigin="anonymous"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
              <div onClick={this.handleUnmute} className={`custom-unmute-button ${isMuted && isPlaying && isHovered ? 'show-unmute' : ''}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="22.444" height="20" viewBox="0 0 22.765 22.765">
                  <path id="no-audio" d="M4.828,3.984A.853.853,0,0,0,4.234,5.45L9.6,10.82H7.118a2.564,2.564,0,0,0-2.56,2.56v3.982a2.564,2.564,0,0,0,2.56,2.56h3.766l5.246,4.665a1.575,1.575,0,0,0,1.052.4,1.6,1.6,0,0,0,.66-.142A1.576,1.576,0,0,0,18.78,23.4V20l6.5,6.5a.853.853,0,1,0,1.207-1.207L5.44,4.243A.853.853,0,0,0,4.828,3.984ZM17.17,5.75a1.591,1.591,0,0,0-1.041.406l-3.368,3,6.019,6.019V7.344a1.576,1.576,0,0,0-.939-1.451A1.6,1.6,0,0,0,17.17,5.75Zm6.526,2.8a.853.853,0,0,0-.689,1.242,11.206,11.206,0,0,1,.5,10.11l1.286,1.286a13.009,13.009,0,0,0-.273-12.18A.849.849,0,0,0,23.7,8.548ZM21.172,11.4a.857.857,0,0,0-.752,1.211,6.243,6.243,0,0,1,.363,4.568l1.331,1.331a7.961,7.961,0,0,0-.142-6.61A.853.853,0,0,0,21.172,11.4Z" transform="translate(-3.975 -3.984)" fill="#fff"></path>
                </svg>
                Unmute
              </div>
      </div>
    );
  }
}
