import React from "react";
import { Route, withRouter, Router } from "react-router-dom";
//Components
import { Manual } from "../pages/Manual";
import { SubPage } from "../pages/SubPage";
import {ManualNotFound} from "../pages/ManualNotFound";

export const Routes = withRouter(({ history }) => {
  return (
    <Router history={history}>
      <div className="app">
        <Route exact path="/:manual/:lang" component={Manual}/>
        <Route exact path="/:manual/" component={Manual}/>
        <Route path="/:manual/preview/:lang" component={Manual}/>
        <Route path="/:manual/:lang/:name" component={SubPage}/>
        <Route path="/404" component={ManualNotFound}/>
      </div>
    </Router>
  );
});
